@import '../../assets/styles/variables';

.list-drawer {
}
.list-drawer--closed {
}
.head {
  position: relative;
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  padding: 15px 40px 15px 15px;
  border-top: 1px solid $gray85;
  cursor: pointer;
  [class^='Nicon'] {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: $red-nissan;
  }
}
.list {
  overflow: hidden;
  position: relative;
  transition: max-height 0.2s ease-in-out;
  .list-drawer--closed & {
    max-height: 0;
  }
  ul,
  li {
    margin: 0;
    padding: 0;
  }
  ul {
    margin-left: 35px;
  }
  table {
    margin: 0 15px 0;
  }
  tr {
    td {
      padding: 8px 0;
      border-top: 1px solid $gray85;
      width: 50%;
    }
    td:first-child {
      padding-right: 15px;
    }
  }
}
.list__wrapper {
  padding: 20px 0 35px;
}
