@import '../../assets/styles/variables';

.item-list {
  margin: 0;
  padding: 0;
}
.item__row {
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
}
.item__changeinfo {
  margin-top: 10px;
}
