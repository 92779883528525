@import '../../assets/styles/variables';

.item__row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.item__col {
  width: 75%;
}
