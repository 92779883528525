@import '../../assets/styles/variables';

.modell-card {
  text-align: left;
  font-weight: 400;
  padding-bottom: 15px;
  border-bottom: 1px dashed $gray78;
  cursor: pointer;
  img {
    margin-bottom: 13px;
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  h2 {
    font-size: 28px;
    margin: 0;
    font-weight: 300;
    text-transform: uppercase;
    color: #000;
    [class^='Nicon'] {
      color: $red-nissan;
      vertical-align: middle;
      position: relative;
      bottom: 2px;
    }
  }
}
.modell-card__slogan {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 35px;
  text-transform: uppercase;
}
.modell-card__price {
  margin-bottom: 10px;
}
.modell-card__price-text {
  font-size: 11px;
  line-height: 1em;
  text-transform: uppercase;
}
.modell-card__price-value {
  font-size: 26px;
  line-height: 1em;
}
.modell-card__link {
  font-size: 11px;
  line-height: 14px;
  text-decoration: underline;
  text-transform: uppercase;
  color: $gray56;
}
