@import '../../assets/styles/variables';

.spec-list {
  background: $white;
  border: 1px solid $gray78;
}
.head {
  display: flex;
  padding: 15px;
  cursor: pointer;
  user-select: none;
}
.head-title {
  font-weight: bold;
  font-size: 16px;
  flex: 1 1 0;
  align-items: center;
  text-transform: uppercase;
}
.head-toggle {
  font-family: 'Nissan Icon Global';
}
.body {
  padding: 0 15px;
  display: none;
}
.body--open {
  display: block;
}
.list {
  margin-bottom: 15px;
  &.list--dlist {
    .list__definition {
      padding-right: 15px;
    }
  }
  &.list--clist {
    .list__definition {
      padding-left: 25px;
      position: relative;
      &::before {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        font-family: 'Nissan Icon Global';
        content: '\E642';
        font-size: 15px;
      }
    }
  }
}

.list__title {
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0 15px;
}
.list__items {
  display: flex;
  font-size: 14px;
  line-height: 22px;
  border-bottom: 1px solid $gray78;
  padding: 7px 15px;
}
.list__definition {
  flex: 1 1 0;
}
.spec-list--sm,
.spec-list--xs {
  .list__title {
    padding: 0;
  }
  .list__items {
    padding: 7px 0;
    border-bottom: none;
  }
}
