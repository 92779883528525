@import '../../assets/styles/variables';

.modal-reset {
  max-width: 500px;
  padding: 25px 0 0;
  &.modal-reset--xs,
  &.modal-reset--sm {
    max-width: 100%;
  }
}
.modal-reset__title {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  position: relative;
  margin-bottom: 50px;
  text-transform: uppercase;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -15px;
    width: 50px;
    height: 4px;
    background-color: $red-nissan;
  }
}
.modal-reset__icon {
  text-align: center;
  margin-bottom: 40px;
}
.modal-reset__nicon {
  color: $red-nissan;
}
.modal-reset__warning {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 80px;
}
.modal-reset__action {
  text-align: center;
}
.modal-reset__botton {
  width: 80%;
  .modal-reset--xs &,
  .modal-reset--sm & {
    width: 100%;
  }
}
