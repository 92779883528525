@import '../../assets/styles/variables';

.summary-image {
  position: relative;
  min-height: 80px;
  height: 100%;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    min-width: 100%;
    display: block;
  }
}
.summary-image__maximize {
  position: absolute;
  display: block;
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  font-family: 'Nissan Icon Global';
  font-size: 24px;
  color: #c3002f;
  right: 15px;
  top: 15px;
  cursor: pointer;
  &.summary-image__maximize--maximized {
    background-color: $red-nissan;
    color: white;
    &::before {
      content: '\E61B';
    }
  }
  &::before {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    content: '\E65C';
  }
}
