@import '../../assets/styles/variables';

.bottom__steps,
.bottom__steps-wrapper {
  display: flex;
}
.bottom__steps {
  flex: 1;
  text-align: left;
  overflow: hidden;
  background-color: $white;
  box-shadow: inset 0px -1px 0px 0px $gray85;
}
.bottom__steps-wrapper {
  transition: transform 0.3s ease;
}
