@import '../../assets/styles/variables';

.infobar {
  display: flex;
  background-color: $white;
  border: 1px solid $red-nissan;
  padding: 15px;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.infobar__text {
  flex: 1;
  padding: 0 15px 0 0;
  border-right: 1px solid $gray78;
  .infobar--2 & {
    max-width: 75%;
  }
  @media print {
    max-width: 70%;
  }
}

.infobar__text-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 1em;
  margin-bottom: 5px;
}
.infobar__text-subtitle {
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.infobar__text-warning {
  font-size: 12px;
  line-height: 19px;
  color: $gray56;
}
.infobar__price {
  padding: 0 15px;
  border-right: 1px solid $gray78;
  .infobar--2 & {
    border-right: none;
  }
  @media print {
    border-right: none;
  }
}
.infobar__widget {
  margin-top: 15px;
}
.infobar__links {
  min-width: 25%;
  padding: 0 0 0 15px;
  font-weight: 600;
  font-size: 14px;
  @media print {
    display: none !important;
  }
  a {
    text-decoration: none;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: $gray20;
  }
  .infobar__link {
    cursor: pointer;
    user-select: none;
  }
  [class^='Nicon'] {
    color: $red-nissan;
    vertical-align: middle;
    &:first-child {
      margin-right: 5px;
    }
    &:last-child {
      margin-left: 5px;
    }
  }
  .nicon-calculator {
    margin-left: 5px;
  }
  .nicon-wedge {
    margin-left: 5px;
  }
}
.infobar--sm,
.infobar--xs {
  flex-direction: column;
  padding: 10px 15px;
  border: none;
  > div {
    width: 100%;
    border: none;
    margin: 0;
    padding: 0;
  }
  .infobar__text {
    margin-bottom: 10px;
  }
  .infobar__text-title {
    font-size: 24px;
  }
  .infobar__text-subtitle {
    font-size: 16px;
  }
  .infobar__price {
    margin-bottom: 15px;
  }
  [class*='price__value'] {
    font-size: 20px;
  }
  .infobar__links {
    font-size: 11px;
  }
}
