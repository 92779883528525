@import '../../assets/styles/variables';

.engine-card {
  border: 1px solid $gray78;
  background-color: $white;
  margin-bottom: 15px;
  hr {
    border: none;
    border-bottom: 1px solid #d9d9d9;
    margin: 10px 0;
  }
}
.engine-card--selected {
  border: 3px solid $red-nissan;
}
.engine-card__title {
  padding: 15px 20px;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
  border-bottom: 1px solid $gray78;
}
.engine-card__parts {
  padding: 0 20px;
  margin-bottom: 15px;
  .nicon {
    &::before {
      width: 30px;
      height: 30px;
      font-size: 30px;
    }
  }
}
.part {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 13px;
  &:last-child {
    margin-bottom: 0;
  }
}
.part__text {
  margin-left: 10px;
}
.part__type {
  font-size: 12px;
  line-height: 1em;
  color: $gray56;
}
.part__title {
  font-weight: 600;
  font-size: 16px;
}

.data {
  padding: 0 20px;
  margin-bottom: 15px;
}
.data__title {
  display: flex;
  align-items: center;
}
.data__title-text {
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  flex: 1;
}
.data__title-icon {
  height: 15px;
  width: 15px;
  font-family: 'Nissan Icon Global';
  user-select: none;
  cursor: pointer;
  &::before {
    content: '\E620';
    font-size: 15px;
    line-height: 1em;
  }
}
.data__list {
  font-size: 11px;
  color: $gray20;
}
.data__list-row {
  display: flex;
  margin-bottom: 7px;
  &:last-child {
    margin-bottom: 0;
  }
}
.data__list-title {
  flex: 1;
  margin-right: 10px;
}
.data__list-value {
  text-align: right;
}
.engine-card__price {
  padding: 0 20px;
  margin-bottom: 25px;
}
.engine-card__widget {
  padding: 0 5px;
  margin-bottom: 25px;
}
.engine-card__action {
  padding: 0 20px 15px;
  button {
    width: 100%;
  }
}
