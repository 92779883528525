.autohitel-widget {
  iframe {
    overflow: hidden;
    width: 300px;
    height: 62px;
    margin: 0;
    padding: 0;
    border: none;
  }
}
.autohitel-widget--open {
  iframe {
    overflow: hidden;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
  }
}
