@import '../../assets/styles/variables';

.overlay {
  position: fixed;
  z-index: 9999;
  top: -5000px;
  right: -5000px;
  bottom: -5000px;
  left: -5000px;
  display: flex;
  align-items: center;
  justify-self: center;
  user-select: none;
  background-color: $white;
}
.overlay__wrapper {
  position: fixed;
  z-index: 10000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  //pointer-events: none;
  -webkit-overflow-scrolling: touch;
}
