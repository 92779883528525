@import '../../assets/styles/variables';

.swatch {
  height: 0;
  margin: 1px;
  padding-bottom: 100%;
  background-color: #4e669c;
  border: 1px solid $gray78;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.swatch--image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.swatch--selected {
  margin: 0;
  border: 2px solid $red-nissan;
  position: relative;
  &::before {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    height: 0;
    border-top: 15px solid $red-nissan;
    border-right: 15px solid $red-nissan;
    border-bottom: 15px solid transparent;
    border-left: 15px solid transparent;
  }
  &::after {
    content: '\E642';
    font-size: 10px;
    display: block;
    width: 10px;
    height: 10px;
    color: $white;
    font-family: 'Nissan Icon Global';
    position: absolute;
    right: 3px;
    top: 1px;
  }
}
.swatch__shine {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  &::before {
    content: '';
    position: absolute;
    display: block;
    height: 0;
    width: 150%;
    left: 50%;
    padding-bottom: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    transform: translate(-50%, -60%) rotate(-10deg);
  }
}
.tooltip {
  min-width: 180px;
  text-overflow: ellipsis;
}
.tooltip__title {
  font-weight: 600;
  margin-bottom: 5px;
}
