@import '../../assets/styles/variables';

.toast {
  padding: 10px 15px;
  margin-bottom: 20px;
  background: $red-light;
  border: 1px solid $red-nissan;
  position: relative;
}
.toast__title {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}
.toast__close {
  position: absolute;
  padding: 5px;
  right: 10px;
  top: 10px;
  color: $red-nissan;
  cursor: pointer;
}
