@import '../../assets/styles/variables';

.list-card--selected {
  border: 3px solid $red-nissan;
  .content {
    border: none;
  }
  .action {
    border-width: 1px 0 0;
  }
}
.content {
  border: 1px solid #c6c6c6;
  background: $white;
  border-bottom: none;
  padding: 15px;
}
.content__title {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.content__list {
  margin-bottom: 20px;
  ul,
  li {
    margin: 0;
    padding: 0;
  }
  ul {
    padding: 0 0 0 20px;
  }
  li {
    list-style: none;
    position: relative;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 6px;
    &::before {
      font-family: 'Nissan Icon Global';
      font-size: 12px;
      content: '\E642';
      display: block;
      position: absolute;
      left: -20px;
      top: 1px;
      width: 12px;
      height: 12px;
    }
  }
}
.content__pricetag {
  font-size: 11px;
  line-height: 16px;
}
.content__price {
  font-size: 26px;
  line-height: 26px;
  font-weight: bold;
  color: $red-nissan;
}
.action {
  border: 1px solid $gray78;
  background: $white;
  border-top: none;
  padding: 0 15px 15px;
  text-transform: uppercase;
  font-weight: 600;
  button {
    width: 100%;
  }
}
.action--selected {
  border-top: 1px solid $red-nissan;
  padding-top: 15px;
  color: $red-nissan;
}
