@import '../../assets/styles/variables';

.top {
  padding: 15px 25px;
  color: $white;
  background-color: $gray10;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  [class*='nicon'] {
    color: $gray56;
  }
}
.top__toggler {
  flex: 1;
  text-align: left;
  white-space: nowrap;
}
.top__toggler-text {
  margin-left: 10px;
  cursor: pointer;
}
.top__steps {
  flex: 2;
  color: $gray78;
  text-align: center;
}
.top__actions {
  flex: 1;
  white-space: nowrap;
  text-align: right;
  a {
    margin-left: 40px;
    &:first-child {
      margin-left: 0;
    }
  }
}
.action {
  margin-left: 40px;
  cursor: pointer;
}
.action--hidden {
  display: none;
}
.action--first {
  margin-left: 0;
}
.action-text {
  margin-left: 10px;
}
.bottom {
  display: flex;
  height: 66px;
}
.bottom__forward,
.bottom__back {
  padding: 0 6px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $gray85;
  position: relative;
  display: none;
  color: $red-nissan;
  cursor: pointer;
  user-select: none;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: $gray85;
    z-index: 1;
  }
  &--visible {
    display: flex;
  }
}
.bottom__forward {
  &::after {
    content: '';
    left: -1px;
  }
}
.bottom__back {
  &::after {
    content: '';
    right: -1px;
  }
}
.bottom__sum {
  display: flex;
}
.main-menu--sm,
.main-menu--xs {
  .top {
    padding: 15px;
  }
  .action {
    margin-left: 15px;
  }
  .action-text {
    display: none;
  }
}
