@import '../../assets/styles/variables';

.modal-save {
  max-width: 500px;
  padding: 25px 0 0;
  &.modal-save--xs,
  &.modal-save--sm {
    max-width: 100%;
  }
}
.modal-save__title {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  position: relative;
  margin-bottom: 20px;
  text-transform: uppercase;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -15px;
    width: 50px;
    height: 4px;
    background-color: $red-nissan;
  }
}
.modal-save__icon {
  text-align: center;
  margin-bottom: 40px;
}
.modal-save__nicon {
  color: $red-nissan;
}

.modal-save__subtitle {
  margin-bottom: 15px;
}
.modal-save__input {
  width: 100%;
  margin-bottom: 15px;
  input {
    border: 1px solid $gray56;
    padding: 10px;
    margin: 0;
    width: 100%;
  }
}
.modal-save__button-wrapper {
  text-align: center;
}
.modal-save__button {
  margin: 0 auto;
  .modal-save--sm &,
  .modal-save--xs & {
    width: 100%;
  }
}
