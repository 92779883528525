@import '../../assets/styles/variables';

.carousel {
  flex-basis: 0px;
  flex-grow: 1;
  min-width: 0;
  height: 100%;
  overflow: hidden;
}
.carousel__wrapper {
  height: 100%;
  position: relative;
}
.carousel__elem {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.carousel__button_maximize,
.carousel__button_mode,
.carousel__button_left,
.carousel__button_right {
  position: absolute;
  display: block;
  width: 50px;
  height: 50px;
  background-color: $white;
  font-family: 'Nissan Icon Global';
  font-size: 24px;
  color: $red-nissan;
  cursor: pointer;
  user-select: none;
  &::before {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.carousel__button_maximize {
  right: 15px;
  top: 15px;
  color: initial;
  &::before {
    content: '\E65C';
  }
}
.carousel__button_maximize--maximized {
  background-color: $red-nissan;
  color: $white;
  &::before {
    content: '\E61B';
  }
}
.carousel__button_mode {
  right: 15px;
  top: 70px;
  color: initial;
}
.carousel__button_mode--exterior {
  &::before {
    content: '\E641';
  }
}
.carousel__button_mode--interior {
  &::before {
    content: '\E667';
  }
}
.carousel__button_right {
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  user-select: none;
  &::before {
    content: '\E634';
  }
}
.carousel__button_left {
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  user-select: none;
  &::before {
    content: '\E622';
  }
}
.info {
  display: inline-flex;
  background-color: $white;
  position: absolute;
  right: 15px;
  bottom: 14px;
  color: $gray20;
  width: 60%;
  max-width: 900px;
}
.info__text {
  width: 70%;
  padding: 10px 15px 30px;
  border-right: 1px solid $gray78;
  text-transform: uppercase;
}
.info__type {
  font-weight: 600;
  font-size: 16px;
}
.info__engine {
  font-size: 14px;
  line-height: 14px;
}
.info-price {
  padding: 10px 15px 30px;
}
.info-price__value {
  white-space: nowrap;
  font-weight: 600;
  font-size: 20px;
  color: $red-nissan;
}
.info-price__widget {
  margin-top: 15px;
}
