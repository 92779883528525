@import '../../assets/styles/variables';

.loader-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader-icon * {
  width: 100%;
  max-width: 100%;
  height: auto;
}
.loader-icon--c-gray {
}
.loader-icon--a-spin {
  @keyframes spin {
    from {
      transform: rotateZ(0deg);
    }
    to {
      transform: rotateZ(360deg);
    }
  }
  animation-name: spin;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
}
