@import '../../assets/styles/variables';

.title-box {
  border: 1px solid $gray85;
  display: inline-block;
  padding: 15px;
}
.title-box__title {
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.list__item {
  display: flex;
  border-bottom: 1px solid $gray85;
  padding: 8px 0;
  &:last-child {
    border-bottom: none;
  }
}
.list__name {
  font-size: 14px;
  flex: 1 1 0;
  text-align: left;
  margin-right: 30px;
}
.list__value {
  font-size: 14px;
  text-align: left;
}
