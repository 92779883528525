@import '../../assets/styles/variables';

.equipment-lister {
  min-width: 600px;
  padding: 25px 10px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.equipment-lister--sm,
.equipment-lister--xs {
  min-width: 100%;
}
.top {
  text-align: center;
  margin-bottom: 40px;
}
.top__super {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  text-transform: uppercase;
}
.top__equipment {
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 10px;
}
.top__price {
  font-size: 24px;
}
.top__box {
  margin: 10px 0 0;
}
.lists {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.lists__title {
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  margin: 0 0 20px 15px;
}
.lists__items {
  height: 100%;
  min-height: 0;
  position: relative;
}
.lists__scroller {
  overflow-y: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
