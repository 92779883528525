@import '../../assets/styles/variables';

.button {
  padding: 10px 20px;
  border: none;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  font-family: 'Nissan Brand', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  outline: none;
  &:focus {
    outline: none;
  }
}
.button--icon {
  position: relative;
  padding-right: 50px;
  text-align: left;
  &::after {
    font-family: 'Nissan Icon Global';
    display: block;
    position: absolute;
    height: 11px;
    width: 11px;
    font-size: 11px;
    font-weight: 300;
    line-height: normal;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.button--icon-print {
  &::after {
    content: '\E631';
  }
}
.button--icon-save {
  &::after {
    content: '\E64D';
  }
}
.button--icon-share {
  &::after {
    content: '\E673';
  }
}
.button--icon-ex {
  &::after {
    content: '\E609';
  }
}
.button--icon-chevron {
  &::after {
    content: '\E634';
  }
}

.button--red {
  background-color: $red-nissan;
  color: $white;
}
.button--gray {
  background-color: $gray93;
  color: $black;
  &::after {
    color: $red-nissan;
  }
}
