@import '../../assets/styles/variables';

.dropdown {
}
.select {
  padding: 0 15px;
  background: $white;
  border: 1px solid $gray78;
  min-height: 50px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  user-select: none;
  .dropdown--open & {
    &::after {
      transform: translateY(-50%) rotateZ(180deg);
    }
  }
  &::after {
    color: $red-nissan;
    font-size: 14px;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    transition: transform 0.2s ease-in-out;
    font-family: 'Nissan Icon Global';
    display: block;
    content: '\E610';
    width: auto;
    height: auto;
  }
}

.list {
  position: relative;
  display: none;
  .dropdown--open & {
    display: block;
  }
  ul,
  li {
    margin: 0;
    padding: 0;
  }
  li {
    list-style: none;
    padding: 4px 15px;
    transition: background-color 0.1s ease-in-out;
    cursor: pointer;
    &:hover {
      background-color: $gray89;
    }
  }
}
.list__content {
  background: $white;
  border: 1px solid $gray78;
  padding: 10px 0;
  position: absolute;
  z-index: 999;
  width: 100%;
  top: 3px;
  user-select: none;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
}
