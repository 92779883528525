@import '../../assets/styles/variables';

.equipment-card {
  background: $white;
  padding: 20px;
  margin-bottom: 15px;
  display: flex;
  border: 1px solid $gray78;
  &:last-child {
    margin-bottom: 0;
  }
}
.equipment-card--selected {
  border: 2px solid $red-nissan;
}
.cardeal {
  width: 50%;
  display: flex;
  padding-right: 20px;
}
.cardeal__image {
  margin-right: 15px;
  width: 45%;
  img {
    width: 100%;
    max-width: 100%;
    display: block;
  }
}
.cardeal__offer {
  width: 55%;
  display: flex;
  flex-direction: column;
  h2 {
    font-weight: 600;
    font-size: 28px;
    margin: 0 0 20px;
  }
}
.cardeal__offer-super {
  font-size: 11px;
  text-transform: uppercase;
}
.cardeal__offer-price {
  font-weight: 600;
  font-size: 26px;
  line-height: 1em;
  margin-bottom: 10px;
  color: $red-nissan;
}
.cardeal__offer-link {
  font-size: 9px;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: $gray56;
  margin-bottom: 10px;
  cursor: pointer;
}
.cardeal__offer-warning {
  font-size: 12px;
  line-height: 19px;
  color: $gray56;
  justify-self: flex-end;
  margin-top: auto;
  margin-bottom: 10px;
}
.specs {
  width: 50%;
  padding-left: 20px;
  border-left: 1px solid $gray85;
  display: flex;
  flex-direction: column;
}
.specs__list {
  flex: 1 1 0;
  h3 {
    margin: 0 0 15px;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
  }
  ul {
    margin: 0;
    padding: 0 0 0 20px;
  }
  li {
    position: relative;
    list-style: none;
    font-size: 12px;
    line-height: 19px;
    margin: 0 15px 5px 0;
    &::before {
      font-family: 'Nissan Icon Global';
      font-size: 12px;
      content: '\E642';
      display: block;
      position: absolute;
      left: -20px;
      top: 1px;
      width: 12px;
      height: 12px;
    }
  }
}
.specs__list-wrapper {
  margin-bottom: 20px;
}
.specs__columns {
  display: flex;
  > div {
    width: 50%;
  }
}
.specoffer {
  margin-bottom: 15px;
  display: none;
}
.specoffer__super {
  font-size: 11px;
  text-transform: uppercase;
}
.specoffer__price {
  font-weight: 600;
  font-size: 26px;
  line-height: 1em;
  color: $red-nissan;
}
.actions {
  display: flex;
  justify-content: space-between;
}
.actions__link {
  font-size: 14px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  cursor: pointer;
  [class^='Nicon'] {
    color: $red-nissan;
    margin-left: 10px;
  }
}
.actions__button {
  button {
    width: 200px;
  }
}

.equipment-card--xs,
.equipment-card--sm,
.equipment-card--md {
  &.equipment-card {
    flex-direction: column;
  }
  .cardeal {
    padding: 0;
    width: 100%;
    margin-bottom: 15px;
  }
  .specs {
    width: 100%;
    padding: 0;
    border: none;
  }
  .cardeal__image,
  .cardeal__offer {
    width: auto;
  }
  .cardeal__image {
    max-width: 250px;
    margin-right: 30px;
  }
}

.equipment-card--xs {
  &.equipment-card {
    padding: 15px;
  }
  .cardeal {
    align-items: center;
  }
  .cardeal__image {
    max-width: 125px;
  }
  .cardeal__offer {
    h2 {
      font-size: 18px;
      margin: 0;
    }
    .cardeal__offer-super,
    .cardeal__offer-price,
    .cardeal__offer-link {
      display: none;
    }
  }
  .specs__list-header {
    padding: 13px 0;
    border-top: 1px solid $gray78;
    border-bottom: 1px solid $gray78;
    position: relative;
    cursor: pointer;
    user-select: none;
    &::after {
      content: '\E66C';
      font-family: 'Nissan Icon Global';
      color: $red-nissan;
      font-weight: 600;
      display: block;
      width: auto;
      height: auto;
      position: absolute;
      right: 2px;
      top: 50%;
      transform: translateY(-50%);
    }
    h3 {
      margin: 0;
    }
  }
  &.equipment-card--open {
    .specs__list-header {
      &::after {
        content: '\E627';
      }
    }
  }
  .specs__list-wrapper {
    padding: 15px 0 0;
    margin: 0;
    overflow: hidden;
    max-height: 0px;
    transition: max-height 0.3s ease-in-out;
  }
  .specs__columns {
    flex-direction: column;
    > div {
      width: 100%;
      &:last-child {
        li:last-child {
          padding-bottom: 15px;
        }
      }
    }
    ul {
      columns: 1;
      display: flex;
      flex-direction: column;
    }
  }
  &.equipment-card--open {
    .specs__list-wrapper {
      max-height: 1000px;
    }
  }
  .specoffer {
    display: block;
  }
  .actions__link {
    display: none;
  }
  .actions__button {
    width: 100%;
    button {
      width: 100%;
    }
  }
}
