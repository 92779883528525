@import '../../assets/styles/variables';

.title-selection {
  //padding: 15px 0 15px 15px;
}
.title {
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.current {
  font-weight: normal;
  font-size: 12px;
}
.selection {
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  display: flex;
}
.selection__title {
  flex: 1 1 0;
}
