@import '../../assets/styles/variables';

.step {
  display: flex;
  align-items: center;
  padding: 0 30px;
  box-shadow: inset 0px -1px 0px 0px $gray85;
  border: 1px solid $gray85;
  border-width: 0 1px 0 0;
  background-color: $white;
  color: $gray56;
  position: relative;
  cursor: pointer;
  user-select: none;
  &:hover {
    &::after {
      position: absolute;
      content: '';
      display: block;
      height: 4px;
      width: calc(100% + 2px);
      background-color: $red-dark;
      bottom: 0;
      left: -1px;
      z-index: 2;
    }
  }
}
.step--active {
  color: $black;
  background-color: $gray85;
  &::after {
    position: absolute;
    content: '';
    display: block;
    height: 4px;
    width: calc(100% + 2px);
    background-color: $red-dark;
    bottom: 0;
    left: -1px;
    z-index: 2;
  }
  .step__icon {
    color: $black;
  }
}
.step--inverted {
  background-color: $black;
  border: none;
  border-left: 1px solid $black;
  border-right: 1px solid $black;
  box-shadow: inset 0px -1px 0px 0px $black;
  color: $white;
  .step__icon {
    color: $white;
  }
  .step__text {
    .step__text-title {
      margin-bottom: 0;
    }
  }
}
.step__icon {
  color: $gray56;
}
.step__text {
  line-height: normal;
  margin-left: 10px;
}
.step__text-title {
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  position: relative;
}
.step__text-selection {
  font-size: 12px;
  white-space: nowrap;
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.step--sm,
.step--xs {
  padding: 0 15px;
  &.step--inverted {
    .step__icon {
      display: block;
    }
    .step__text {
      display: none;
    }
  }
  .step__icon {
    display: none;
  }
  .step__text {
    margin-left: 0;
  }
  .step__text-title {
    white-space: normal;
  }
}
