.modal {
  display: none;
}
.modal--visible {
  display: block;
}
.modal__overlay {
  position: fixed;
  z-index: 9999;
  top: -5000px;
  right: -5000px;
  bottom: -5000px;
  left: -5000px;
  background-color: rgba(0, 0, 0, 0.7);
}
.modal__wrapper {
  position: fixed;
  z-index: 10000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  //pointer-events: none;
  -webkit-overflow-scrolling: touch;
}
.modal__content {
  position: relative;
  z-index: 10001;
  background-color: white;
  padding: 30px;
  min-width: 300px;
  min-height: 200px;
  .modal--side & {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    min-height: 100%;
    padding: 25px 30px;
  }
  .modal--side.modal--sm &,
  .modal--side.modal--xs & {
    padding: 20px 15px;
  }
}
.close {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  user-select: none;
  .modal--side & {
    top: 20px;
  }
}
.modal--sm,
.modal--xs {
  .modal__content {
    min-width: 100%;
    min-height: 100%;
  }
}
