@import '../../assets/styles/variables';

.info-image {
  background-color: $white;
  display: flex;
  position: relative;
  &.info-image--big {
    flex-direction: column;
    height: 100%;
  }
}
.info {
  padding: 10px;
  flex-basis: 50%;
  max-width: 50%;
  .info-image--big & {
    flex-basis: initial;
    max-width: initial;
  }
}
.info__name {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
}
.info__engine {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 10px;
}
.info__super {
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  text-transform: uppercase;
}
.info__price {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: $red-nissan;
  margin-bottom: 7px;
}
.info__link {
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  cursor: pointer;
  [class^='Nicon'] {
    vertical-align: middle;
    color: $red-nissan;
  }
}
.image {
  flex-basis: 50%;
  max-width: 50%;
  position: relative;
  .info-image--big & {
    flex-basis: initial;
    max-width: initial;
    flex: 1 1 0;
  }
}
.image__img {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.button__maximize {
  position: absolute;
  display: block;
  width: 40px;
  height: 40px;
  background-color: $white;
  font-family: 'Nissan Icon Global';
  font-size: 16px;
  color: $gray20;
  cursor: pointer;
  z-index: 999;
  &::before {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.button__maximize {
  right: 5px;
  top: 5px;
  &::before {
    content: '\E65C';
  }
}
.button__maximize--maximized {
  background-color: $red-nissan;
  color: $white;
  &::before {
    content: '\E65C';
  }
}
.controls {
  display: flex;
}
.controls__btn-external,
.controls__btn-internal {
  width: 50%;
  background-color: $gray78;
  text-align: center;
  padding: 10px 0;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  user-select: none;
  cursor: pointer;
}
.controls__btn--active {
  background-color: $red-nissan;
  position: relative;
  color: $white;
  &::before {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-bottom: 10px solid $red-nissan;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }
}
