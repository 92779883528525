@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.equipment-page {
  width: 100%;
  background-color: $gray95;
  display: flex;
  flex-direction: column;
}
.main {
  flex: 1 1 auto;
  display: flex;
}
.content-wrapper {
  padding: 15px 0 15px 15px;
  display: flex;
  width: 100%;
  flex-direction: column;
}
.content-scroll {
  flex-basis: 0px;
  flex-grow: 1;
  padding-right: 15px;
}
.info {
  margin-bottom: 20px;
}
.info__title {
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  line-height: normal;
}
.info__current {
  font-size: 16px;
}
.share-modal__iframe {
  min-width: 550px;
  height: 100%;
  border: none;
  padding: 25px 0 0;
  .summary-page--sm &,
  .summary-page--xs & {
    min-width: 100%;
  }
}
