@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.color-page {
  width: 100%;
  height: 800px;
  background-color: $gray95;
  display: flex;
  flex-direction: column;
}
.main {
  flex: 1 1 auto;
  display: flex;
  .color-page--xs & {
    flex-direction: column;
  }
}
.left {
  flex-basis: 25%;
  max-width: 25%;
  display: flex;
  flex-direction: column;
  padding-right: 15px;
  flex-grow: 1;
  transition: max-width 0.2s ease-in-out, flex-basis 0.2s ease-in-out;
  .color-page--md & {
    flex-basis: 35%;
    max-width: 35%;
  }
  .color-page--sm & {
    flex-basis: 40%;
    max-width: 40%;
  }
  .color-page--xs & {
    flex-basis: 100%;
    max-width: 100%;
    padding-right: 0;
  }
}
.title-selection___wrapper {
  padding: 15px 0 15px;
}
.left--minimized {
  flex-basis: 0;
  max-width: 0;
  padding-right: 0;
  overflow: hidden;
}
.info {
  margin-bottom: 20px;
}
.info__title {
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  line-height: normal;
}
.info__current {
  font-size: 16px;
}
.colors {
  display: flex;
  flex: 1 1 0;
  padding: 15px 0 15px 15px;
  overflow-x: hidden;
}
.colors__wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.colors__scroll {
  overflow-x: hidden;
  overflow-y: scroll;
  flex-basis: 0px;
  flex-grow: 1;
  padding-right: 5px;
  .color-page--xs & {
    padding-right: 15px;
  }
}
.color-list-title-wrapper {
  padding-bottom: 30px;
  border-bottom: 1px solid $gray78;
  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
}
.color-list-title {
  padding: 15px 0;
  font-weight: 600;
}
.theme-list {
  > div {
    margin-bottom: -15px;
  }
}
.step-button {
  background-color: $white;
  padding: 10px 15px;
  margin-right: -15px;
  box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.1);
  button {
    width: 100%;
    font-weight: normal;
    .color-page--xs & {
      padding: 15px 20px;
    }
  }
  .color-page--xs & {
    padding: 0;
    margin-right: 0;
    box-shadow: none;
  }
}
.right {
  display: flex;
  flex-direction: column;
  flex-basis: 75%;
  max-width: 75%;
  transition: max-width 0.2s ease-in-out, flex-basis 0.2s ease-in-out;
  .color-page--md & {
    flex-basis: 65%;
    max-width: 65%;
  }
  .color-page--sm & {
    flex-basis: 60%;
    max-width: 60%;
  }
  .color-page--xs & {
    flex-basis: 100%;
    max-width: 100%;
  }
}
.right--maximized {
  flex-basis: 100%;
  max-width: 100%;
}
.right--hidden {
  display: none;
}
.carousel-wrapper {
  flex: 1 1 0;
}
.share-modal__iframe {
  min-width: 550px;
  height: 100%;
  border: none;
  padding: 25px 0 0;
  .summary-page--sm &,
  .summary-page--xs & {
    min-width: 100%;
  }
}
