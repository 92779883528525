@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.summary-page {
  width: 100%;
  background-color: $gray95;
  display: flex;
  flex-direction: column;
  @media print {
    display: block;
    height: initial;
  }
}
.menu {
  @media print {
    display: none;
  }
}
.main {
  overflow-x: hidden;
  flex: 1 1 0;
  @media print {
    overflow: unset;
  }
}
.main__padding {
  padding: 15px;
  @include media-breakpoint-down(sm) {
    padding: 0;
  }
  @media print {
    padding: 20px;
    > div {
      flex-direction: column;
      display: block;
    }
  }
}
.main__top {
  margin-bottom: 15px;
  @include media-breakpoint-down(sm) {
    margin-bottom: 0;
  }
}
.main__left {
  display: flex;
  flex-direction: column;
  @include media-breakpoint-down(sm) {
    margin-bottom: 5px;
  }
  @media print {
    display: block;
    width: 100% !important;
    max-width: 100% !important;
  }
}
.summary-image__wrapper {
  margin-bottom: 15px;
  @include media-breakpoint-down(sm) {
    margin-bottom: 0;
  }
  @media print {
    width: 100%;
  }
}
.actions {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  @media print {
    display: none;
  }
  @include media-breakpoint-down(sm) {
    display: none;
  }
  button {
    margin: 0 10px 10px 0;
  }
}
.action-boxes__wrapper {
  margin-bottom: -15px;
  @include media-breakpoint-down(sm) {
    margin-bottom: 0;
  }
}
.action-box__wrapper {
  margin-bottom: 15px;
  @include media-breakpoint-down(sm) {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.main__right {
  display: flex;
  flex-direction: column;
  @media print {
    display: block;
    width: 100% !important;
    max-width: 100% !important;
  }
}
.finisher {
  margin-bottom: 30px;
  @media print {
    display: none;
  }
  @include media-breakpoint-down(sm) {
    padding: 15px 15px 5px;
    margin-bottom: 0;
  }
}
.finisher__wrapper {
  padding: 15px 10px;
  background-color: #fff;
}
.finisher__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 1em;
  margin-bottom: 15px;

  @include media-breakpoint-down(sm) {
    font-size: 18px;
  }
}
.finisher__matches {
  margin-bottom: 20px;
}
.finisher__match {
  margin-bottom: 10px;
}
.tabs {
  margin-bottom: 30px;
  display: none;
  .summary-page--sm &,
  .summary-page--xs & {
    display: block;
    padding: 15px 15px 5px;
    margin-bottom: 0;
    @media print {
      display: none;
    }
  }
}
.tabs__title {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2em;
  margin-bottom: 20px;
  display: none;
  .summary-page--sm &,
  .summary-page--xs & {
    display: block;
  }
  &::before {
    content: '';
    display: block;
    background-color: $red-nissan;
    width: 45px;
    height: 5px;
    margin-bottom: 10px;
  }
}
.summary {
  margin-bottom: 30px;
  @include media-breakpoint-down(sm) {
    padding: 15px 15px 5px;
    margin-bottom: 0;
  }
}
.summary__title {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2em;
  margin-bottom: 20px;
  .summary-page--sm &,
  .summary-page--xs & {
    display: none;
  }
  &::before {
    content: '';
    display: block;
    background-color: $red-nissan;
    width: 45px;
    height: 5px;
    margin-bottom: 10px;
  }
}
.specification {
  margin-bottom: 30px;
  font-size: 14px;
  @include media-breakpoint-down(sm) {
    padding: 15px 15px 5px;
    margin-bottom: 0;
  }
}
.specification--print-only {
  display: none;
  @media print {
    display: block;
  }
}
.specification__title {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2em;
  margin-bottom: 20px;
  .summary-page--sm &,
  .summary-page--xs & {
    display: none;
  }
  &::before {
    content: '';
    display: block;
    background-color: $red-nissan;
    width: 45px;
    height: 5px;
    margin-bottom: 10px;
  }
}

.specification__wrapper {
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
}

.send-modal__iframe {
  min-width: 550px;
  height: 100%;
  border: none;
  padding: 25px 0 0;
  .summary-page--sm &,
  .summary-page--xs & {
    min-width: 100%;
  }
}
.share-modal__iframe {
  min-width: 550px;
  height: 100%;
  border: none;
  padding: 25px 0 0;
  .summary-page--sm &,
  .summary-page--xs & {
    min-width: 100%;
  }
}

.actions-sm {
  display: none;
  margin: 0 -7.5px;
  @include media-breakpoint-down(sm) {
    display: flex;
    padding: 15px;
    @media print {
      display: none;
    }
  }
  button {
    width: 50%;
    margin: 0 7.5px 10px 7.5px;
  }
}
