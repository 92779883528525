@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.modell-page--xs {
  color: #000;
}
.container-wrapper {
  max-width: 1170px;
  margin: 0 auto;
}
.title {
  font-weight: 300;
  font-size: 48px;
  line-height: 1em;
  margin: 50px 0;
  text-align: center;
  text-transform: uppercase;
  .modell-page--sm &,
  .modell-page--xs & {
    margin: 30px 0 40px;
    font-size: 30px;
  }
}
.modell-card-wrapper {
  margin-bottom: 20px;
  .modell-page--sm &,
  .modell-page--xs & {
    padding: 0 7.5px;
  }
}
