@import '../../assets/styles/variables';

.tab-list {
}
.tabs {
  display: flex;
  //margin-bottom: 20px;
}
.tabs__tab {
  flex-basis: 50%;
  max-width: 50%;
  background-color: $gray78;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  position: relative;
  border-right: 1px solid $gray85;

  &:last-of-type {
    border-right: none;
  }
}

.tabs__tab--active {
  background-color: $red-nissan;
  color: $white;
  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: -9px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid $red-nissan;
  }
}
