@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.summary-car-card {
  border: 1px solid $gray78;
  display: flex;
  color: $body;

  &:hover {
    color: $body;
    text-decoration: none;
  }
}
.summary-car-card__image {
  background-color: #fff;
  display: flex;
  align-items: center;
  img {
    width: 100%;
    max-width: 200px;
  }
}
.summary-car-card__title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 20px;
  position: relative;

  @include media-breakpoint-down(sm) {
    font-size: 18px;
  }
}
.summary-car-card__content {
  padding: 20px;
  background-color: #efefef;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  flex: 1;
}
.summary-car-card__dealer {
  color: #888888;
  text-transform: uppercase;
  margin-bottom: 6px;
}
.summary-car-card__text {
  margin-bottom: 6px;
  font-weight: 600;
}
.summary-car-card__link {
  vertical-align: middle;
  text-transform: uppercase;
  font-weight: 700;
  color: $red-nissan;

  @include media-breakpoint-down(sm) {
    margin-bottom: 10px;
  }

  [class^='Nicon'] {
    color: $red-nissan;
    vertical-align: middle;
    &:first-child {
      margin-right: 5px;
    }
    &:last-child {
      margin-left: 5px;
    }
  }
}
.summary-car-card__price {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 23px;
  text-align: right;
}
