@import '../../assets/styles/variables';

.change-info {
  color: $gray90;
  background: $gray15;
  position: relative;
}
.arrow__wrapper {
  padding: 0 7px;
}
.arrow__content {
  position: relative;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -3px;
    left: var(--changeinfo-arrow-left);
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid $gray15;
  }
}
.change-info__top {
  padding: 15px;
}
.change-info__bottom {
  padding: 15px;
  display: none;
}
.change-info__bottom--open {
  display: block;
}
.heading {
  position: relative;
  display: flex;
  align-items: center;
}
.heading__text {
  flex: 1 1 0;
  font-size: 11px;
}
.heading__icon {
  font-family: 'Nissan Icon Global';
  cursor: pointer;
}
.remove,
.change {
  margin-bottom: 20px;
}
.list__title {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
}
.list__item {
  display: flex;
  padding: 5px 10px;
  margin-bottom: 2px;
  background-color: $gray20;
  font-size: 14px;
}
.list__left {
  flex: 1 1 0;
  padding-right: 15px;
}
.price {
  display: flex;
  text-transform: uppercase;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid $gray56;
  font-weight: bold;
  font-size: 16px;
}
.price__label {
  flex: 1 1 0;
}
.actions {
  display: flex;
  button {
    font-size: 13px;
    padding: 10px;
    width: 100%;
    &::after {
      right: 10px;
    }
  }
}
.actions__left {
  flex: 1 1 0;
  margin-right: 7.5px;
}
.actions__right {
  flex: 1 1 0;
  margin-left: 7.5px;
}
