$black: #000000;
$white: #ffffff;

$body: #212529;
$red-nissan: #c3002f;
$red-dark: #a81727;
$red-light: #f5e7ea;

$gray10: #191919;
$gray15: #252525;
$gray20: #343434;
$gray56: #8e8e8e;
$gray78: #c6c6c6;
$gray85: #d9d9d9;
$gray89: #e3e2e2;
$gray93: #eeeeee;
$gray90: #f4f4f4;
$gray95: #f1f1f1;
