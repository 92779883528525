@import '../../assets/styles/variables';

.summary-list {
  [class^='Nicon'] {
    vertical-align: middle;
    color: $red-nissan;
  }
}
.item {
  padding: 15px;
  background: $white;
  border: 1px solid $gray78;
  font-size: 14px;
  text-transform: uppercase;
}
.item__title {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 15px;
  cursor: pointer;
  span {
    margin-right: 3px;
  }
  [class^='Nicon'] {
    vertical-align: middle;
    color: black;
  }
}
.item__line {
  display: flex;
  //align-items: flex-end;
}
.item__name {
  flex: 1 1 0;
}
.item__price {
  text-align: right;
  position: relative;
}
.item__super {
  white-space: nowrap;
  position: absolute;
  top: -18px;
  right: 0;
  font-weight: 300;
  font-size: 11px;
  line-height: 17px;
}
.item__value {
  font-weight: bold;
}
.price {
  padding: 20px 15px;
  background: $white;
  border: 1px solid $gray78;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.price__price {
  margin: 10px 20px 10px 0;
}
.price__widget {
  margin: 10px 0;
}
.calc {
  padding: 15px;
  background: $white;
  border: 1px solid $gray78;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  @media print {
    display: none;
  }
}
.calc__text {
  margin: 0 5px;
}
.reset {
  padding: 15px;
  background: $white;
  border: 1px solid $gray78;
  text-align: center;
  cursor: pointer;
  user-select: none;
  [class^='Nicon'] {
    position: relative;
    bottom: 2px;
  }
  @media print {
    display: none;
  }
}
.reset__text {
  margin: 0 5px;
}
.tos {
  padding: 15px;
  background: $white;
  border: 1px solid $gray78;
  text-align: center;
  @media print {
    display: none;
  }
}
.tos__text {
  margin: 0 5px;
}
