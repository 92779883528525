@import '../../assets/styles/variables';

.price {
}
.price__title {
  text-transform: uppercase;
  font-size: 11px;
  line-height: 1.3em;
}
.price__value {
  font-weight: 600;
  font-size: 28px;
  line-height: 1em;
  color: $red-nissan;
}
