@import '../../assets/styles/variables';

.image-card {
  margin-bottom: 0;
}
.image-card--selected {
  border: 3px solid $red-nissan;
  .content,
  .image {
    border: none;
  }
  .action {
    border-width: 1px 0 0;
  }
}
.image {
  border: 1px solid #c6c6c6;
  border-width: 1px 1px 0;
  img {
    display: block;
    margin: 0 auto;
    width: 100%;
  }
}
.content {
  border: 1px solid #c6c6c6;
  border-width: 0 1px 1px;
  background: $white;
  border-bottom: none;
  padding: 15px;
}
.content__title {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.content__pricetag {
  font-size: 11px;
  line-height: 16px;
}
.content__price {
  font-size: 26px;
  line-height: 26px;
  font-weight: bold;
  color: $red-nissan;
}
.action {
  border: 1px solid $gray78;
  background: $white;
  border-top: none;
  padding: 0 15px 15px;
  text-transform: uppercase;
  font-weight: 600;
  button {
    width: 100%;
  }
}
.action--selected {
  border-top: 1px solid $red-nissan;
  padding-top: 15px;
  color: $red-nissan;
}
